<script setup lang="ts">
/**
 * @author Irakli Kandelaki
 */

/** IMPORT HOOKS */
import { ref, computed, watch } from "vue";
// import { useRoute } from "vue-router";

/** IMPORT COMPOSABLES */
import { useMyComposable } from "@/composables/core/conf";
import { humanReadableNum } from "@/composables/utils/template";

/** IMPORT STORE */
import { useBuildingStore } from "@/store/building";

/** IMPORT COMPONENTS */
import Button from "@/components/regularComponents/UIComponents/Button.vue";
import ShadowMap from "@/components/regularComponents/UIComponents/SVG/ShadowMap.vue";
import EnlargeLayout from "@/components/regularComponents/UIComponents/SVG/EnlargeLayout.vue";
import InteriorGallery from "@/components/regularComponents/UIComponents/SVG/InteriorGalerry.vue";
import DownloadPlan from "@/components/regularComponents/UIComponents/SVG/DownloadPlan.vue";
// import InnerPDF from "@/components/regularComponents/UIComponents/PDF/InnerPDF.vue";
import ModalX from "@/components/regularComponents/UIComponents/SVG/actions/ModalX.vue";
import GumbatiSlider from "@/components/smartComponents/GumbatiSlider/GumbatiSlider.vue";
import BaseModal from "@/components/regularComponents/UIComponents/Modals/BaseModal.vue";
import Sidebar from "@/components/regularComponents/UIComponents/Sidebar.vue";
import BackDrop from "@/components/regularComponents/UIComponents/BackDrop.vue";
import Gallery from "@/components/smartComponents/Gallery/Gallery.vue";
import SunlightMap from "../SunlightMap.vue";
// import Vue3Html2pdf from "@/components/regularComponents/UIComponents/PDF/vue3-html2pdf.vue";
import DollarSign from "../SVG/DollarSign.vue";
import LariSign from "../SVG/LariSign.vue";
import EuroSign from "../SVG/EuroSign.vue";
import Calculator from "@/components/regularComponents/UIComponents/SVG/Calculator.vue";
import CalculatorFrame from "@/components/regularComponents/UIComponents/SVG/CalculatorFrame.vue";
import PaymentCalculator from "@/components/regularComponents/UIComponents/Project/PaymentCalculator/PaymentCalculator.vue";
import BookingForm from "@/components/regularComponents/FormComponents/BookingForm.vue";

/** IMPORT TYPES */
import { useMainStore } from "@/store/main";

/** DEFINE TYPES */
// interface html2pdf {
//   generatePdf: Function;
// }

/** PROPS */
defineProps<{
  is3dImageActive: boolean;
}>();

/** EMIT */
const emit = defineEmits(["3dImageActive"]);

/** RETRIEVE ROUTE */
// const route = useRoute();

/** RETRIEVE STORE */
const buildingStore = useBuildingStore();
const mainStore = useMainStore();

const conf = useMyComposable();

/** REACTIVE DATA */
// const html2Pdf: Ref<html2pdf | null> = ref(null);
const isLayoutModalOpen = ref(false);
const isSunlightModalOpen = ref(false);
const isGalleryModalOpen = ref(false);
const isPaymentCalculatorOpen = ref(false);
const showContactRequestModal = ref(false);
const activeSeason = ref(1);
// const pdfKey = ref(0);

const getInstalmentValue = computed(() => {
  if (!buildingStore?.selectedFlat?.price) return;
  const res = (buildingStore?.selectedFlat?.price / 100) * 30;

  return humanReadableNum(buildingStore.getPrice(res, true));
});

// const pdfTitle = computed(() => {
//   const id = buildingStore.selectedFlat?.id || 0;
//   const projectTitle = buildingStore?.selectedProject?.title || "Apartment";
//   const floorTitle = buildingStore.selectedFloor?.title;
//   const flatType = buildingStore.selectedFlat?.flatTypeTitle;

//   return `${id}-${projectTitle}-${floorTitle}-${flatType}`;
// });

const layoutImages = computed(() => {
  if (!buildingStore?.selectedFlat) return;

  return buildingStore?.selectedFlat?.flat_type?.image3d.concat(
    buildingStore?.selectedFlat?.flat_type?.image2d
  );
});

/** WATCHERS */
// watch(
//   route,
//   (n) => {
//     pdfKey.value++;
//   },
//   {
//     immediate: true,
//   }
// );
</script>

<template>
  <div
    class="flat__footer pos-abs d-flex j-btw"
    :class="{ 'flat__footer--vertical-tablet': mainStore.isVerticalTablet }"
  >
    <div class="d-flex j-btw w-full">
      <div class="flat__footer-left-col">
        <div
          class="flat__footer-left-box d-flex al-center"
          @click="isLayoutModalOpen = true"
          key="enlarge"
        >
          <div class="flat__footer-left-box-icon">
            <EnlargeLayout />
          </div>
          <span>{{ $t("enlarge layout") }}</span>
        </div>

        <div
          class="flat__footer-left-box d-flex al-center"
          @click="isSunlightModalOpen = true"
          v-if="buildingStore?.selectedFlat?.flat_type?.image_sunlight?.[0]?.devices"
          key="map"
        >
          <div class="flat__footer-left-box-icon">
            <ShadowMap />
          </div>
          <span>{{ $t("shadow map") }}</span>
        </div>

        <div
          class="flat__footer-left-box d-flex al-center"
          v-if="buildingStore?.selectedFlat?.flat_type?.gallery?.length"
          @click="isGalleryModalOpen = true"
          key="gallery"
        >
          <div class="flat__footer-left-box-icon">
            <InteriorGallery />
          </div>
          <span>{{ $t("interior gallery") }}</span>
        </div>

        <a
          :href="`${conf.apiUrl?.split('/api')[0]}/flat-pdf/${mainStore?.lang}/${
            buildingStore?.selectedFlat?.id
          }?view=${is3dImageActive ? '3d' : '2d'}`"
          target="_blank"
          class="flat__footer-left-box d-flex al-center"
          key="pdf"
        >
          <div class="flat__footer-left-box-icon">
            <DownloadPlan />
          </div>
          <span>{{ $t("download plan") }}</span>
        </a>

        <div
          v-if="
            mainStore.windowWidth < 1248 &&
            !buildingStore?.selectedFlat?.hide_price?.hide_price
          "
          class="flat__footer-left-box d-flex al-center"
          @click="isPaymentCalculatorOpen = true"
          key="calculator"
        >
          <div class="flat__footer-left-box-icon">
            <CalculatorFrame />
          </div>
        </div>
      </div>

      <div
        v-if="
          mainStore?.windowWidth < 1248 &&
          !mainStore.isVerticalTablet &&
          mainStore.windowWidth >= 984
        "
        class="flat__footer-mid-col d-flex dir-col al-center"
      >
        <template v-if="!buildingStore?.selectedFlat?.hide_price?.hide_price">
          <h4 class="uppercase">
            {{ $t("instalment from") }}
          </h4>

          <div class="d-flex">
            <h3>{{ getInstalmentValue }}</h3>
            <DollarSign v-if="buildingStore.currency === 'usd'" />
            <EuroSign v-else-if="buildingStore.currency === 'eur'" />
            <LariSign v-else />
          </div>
        </template>

        <Button
          class="flat__footer-request-btn"
          btn-text="send request"
          :btn-type="8"
          @click="showContactRequestModal = true"
        />
      </div>

      <div class="flat__footer-image-toggler d-flex">
        <Button
          :class="{ active: is3dImageActive }"
          @click="emit('3dImageActive', true)"
          :btn-type="8"
          btn-text="3d view"
          icon="cube"
          :hasPointer="buildingStore?.selectedFlat?.flat_type?.image2d ? true : false"
        />
        <Button
          v-if="buildingStore?.selectedFlat?.flat_type?.image2d"
          :class="{ active: !is3dImageActive }"
          @click="emit('3dImageActive', false)"
          :btn-type="8"
          btn-text="2d plan"
          icon="rect"
        />
      </div>
    </div>
    <div
      v-if="mainStore.isVerticalTablet || mainStore.windowWidth < 984"
      class="flat__footer-bottom d-flex j-btw"
    >
      <div class="d-flex dir-col">
        <h4 class="uppercase">
          {{ $t("instalment from") }}
        </h4>
        <div class="d-flex">
          <h3>{{ getInstalmentValue }}</h3>
          <DollarSign v-if="buildingStore.currency === 'usd'" />
          <EuroSign v-else-if="buildingStore.currency === 'eur'" />
          <LariSign v-else />
        </div>
      </div>
      <div class="flat__footer-bottom-price d-flex al-center">
        <div class="d-flex">
          <h3>
            {{
              !buildingStore?.selectedFlat?.hide_price?.hide_price
                ? humanReadableNum(
                    buildingStore?.getPrice(buildingStore?.selectedFlat?.price, true)
                  )
                : $t("hidden")
            }}
          </h3>
          <template v-if="!buildingStore?.selectedFlat?.hide_price?.hide_price">
            <DollarSign v-if="buildingStore.currency === 'usd'" />
            <EuroSign v-else-if="buildingStore.currency === 'eur'" />
            <LariSign v-else />
          </template>
        </div>
        <Button
          class="flat__footer-request-btn al-center"
          btn-text="send request"
          :btn-type="8"
          @click="showContactRequestModal = true"
        />
      </div>
    </div>
  </div>
  <!-- <ClientOnly>
    <Vue3Html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="2550"
      :filename="pdfTitle"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      ref="html2Pdf"
    >
      <template v-slot:pdf-content>
        <InnerPDF
          :flatData="buildingStore?.selectedFlat"
          :projTitle="buildingStore?.selectedProject?.title || 'GUMBATI'"
          :hidePrice="!!buildingStore?.selectedFlat?.hide_price?.hide_price"
          :key="pdfKey"
        />
      </template>
    </Vue3Html2pdf>
  </ClientOnly> -->

  <Teleport to="body">
    <Transition name="fade-out">
      <BaseModal
        class="base-modal--flats-gallery"
        v-if="isGalleryModalOpen"
        @close="isGalleryModalOpen = false"
        maxWidth="unset"
      >
        <GumbatiSlider
          v-if="buildingStore?.selectedFlat?.flat_type?.gallery?.length"
          :images="buildingStore?.selectedFlat?.flat_type?.gallery!"
        />
      </BaseModal>
    </Transition>
  </Teleport>

  <Teleport to="body">
    <Transition name="fade-out">
      <BaseModal
        class="base-modal--flats-gallery"
        v-if="isSunlightModalOpen"
        :has-close="false"
        @close="isSunlightModalOpen = false"
        maxWidth="unset"
      >
        <template #head>
          <div class="sunlight-map__buttons">
            <Button
              :btn-type="8"
              btn-text="summer"
              :class="{ active: activeSeason === 1 }"
              @click="activeSeason = 1"
            />
            <Button
              :btn-type="8"
              btn-text="winter"
              :class="{ active: activeSeason === 2 }"
              @click="activeSeason = 2"
            />
          </div>
          <div
            class="base-modal--close pos-rel pointer"
            @click="isSunlightModalOpen = false"
          >
            <ModalX />
          </div>
        </template>
        <SunlightMap :active-season="activeSeason" />
      </BaseModal>
    </Transition>
  </Teleport>

  <Teleport to="body">
    <Transition name="fade">
      <Gallery
        v-if="isLayoutModalOpen"
        :isOpen="true"
        @close="isLayoutModalOpen = false"
        imgTitle="alt"
        :currentImages="is3dImageActive ? 1 : 0"
        :data="layoutImages"
        :imageStyle="false"
      />
    </Transition>
  </Teleport>

  <Teleport to="body">
    <Transition name="fade-out">
      <BaseModal
        class="base-modal--flats-gallery sunlight-map-modal"
        v-if="isSunlightModalOpen"
        :has-close="false"
        @close="isSunlightModalOpen = false"
        maxWidth="unset"
      >
        <template #head>
          <div class="sunlight-map__buttons">
            <Button
              :btn-type="8"
              btn-text="summer"
              :class="{ active: activeSeason === 1 }"
              @click="activeSeason = 1"
            />
            <Button
              :btn-type="8"
              btn-text="winter"
              :class="{ active: activeSeason === 2 }"
              @click="activeSeason = 2"
            />
          </div>
          <div
            class="base-modal--close pos-rel pointer"
            @click="isSunlightModalOpen = false"
          >
            <ModalX />
          </div>
        </template>
        <SunlightMap :active-season="activeSeason" />
      </BaseModal>
    </Transition>

    <Transition name="slide-from-right">
      <Sidebar
        title="PAYMENT CALCULATOR"
        v-if="mainStore.windowWidth > 983 && isPaymentCalculatorOpen"
        @closeModal="isPaymentCalculatorOpen = false"
      >
        <template #icon><Calculator /></template>
        <PaymentCalculator />
      </Sidebar>
    </Transition>

    <Transition name="fade">
      <BaseModal
        title="PAYMENT CALCULATOR"
        :expandToFullScreen="true"
        class="sidebar--contact-request"
        v-if="mainStore.windowWidth < 984 && isPaymentCalculatorOpen"
        @close="isPaymentCalculatorOpen = false"
      >
        <PaymentCalculator />
      </BaseModal>
    </Transition>

    <Transition name="fade">
      <BaseModal
        title="SEND REQUEST"
        :expandToFullScreen="true"
        class="sidebar--contact-request booking-modal"
        v-if="mainStore.windowWidth < 984 && showContactRequestModal"
        @close="showContactRequestModal = false"
      >
        <BookingForm
          emailRequired
          title="Contact Request"
          formType="flatBooking"
          noteText="We will definitely contact you within the next few days or hours and provide you with detailed information about the apartment."
        />
      </BaseModal>
    </Transition>

    <Transition name="slide-from-right">
      <Sidebar
        v-if="mainStore.windowWidth > 983 && showContactRequestModal"
        :title="$t('send request')"
        extraClass="sidebar--contact-request"
        @closeModal="showContactRequestModal = false"
      >
        <BookingForm
          emailRequired
          title="Contact Request"
          formType="flatBooking"
          noteText="We will definitely contact you within the next few days or hours and provide you with detailed information about the apartment."
        />
      </Sidebar>
    </Transition>

    <Transition name="fade">
      <BackDrop
        showContactRequestModal
        v-if="showContactRequestModal"
        @click="showContactRequestModal = false"
      />
    </Transition>

    <Transition name="fade">
      <BackDrop
        isPaymentCalculatorOpen
        v-if="isPaymentCalculatorOpen"
        @click="isPaymentCalculatorOpen = false"
      />
    </Transition>
  </Teleport>
</template>

<style lang="scss">
.flat__footer {
  bottom: 0;
  padding-right: 40px;
  padding-left: 48px;
  padding-bottom: 48px;
  width: 100%;

  @include maxq(desktop) {
    padding-right: 20px;
    padding-left: 28px;
    padding-bottom: 28px;
    .w-full {
      flex-direction: column;
      align-items: center;
      justify-content: initial;
    }
  }

  @include maxq(tablet) {
    .w-full {
      flex-direction: row;
      align-items: flex-end;
      width: 100%;
      justify-content: space-between;
    }
    &--vertical-tablet {
      flex-direction: column;
    }
  }

  @include maxq(mobile) {
    flex-direction: column;
    padding-bottom: 16px;
    padding-right: 16px;
    padding-left: 16px;
  }

  &-image-toggler {
    display: flex;
    border: 1px solid $brightGray;
    padding: 8px;
    order: 2;

    @include maxq(desktop) {
      padding: 7px 8px;
      order: 1;
      width: fit-content;
      margin-bottom: 25px;
    }

    @include maxq(tablet) {
      margin-bottom: 0;
      order: initial;
    }

    @include maxq(mobile) {
      margin-bottom: 0;
    }

    @include maxq(mobile-sm) {
      padding: 4px;
    }

    .button--8 {
      padding: 12px 16px;
      @include maxq(desktop) {
        padding: 12px 16px;
        height: 48px;
      }
      @include maxq(tablet) {
        span {
          display: none;
        }
      }
      @include maxq(mobile) {
        height: 34px;
      }
      @include maxq(mobile-sm) {
        padding: 8px 12px;
        .button--has-icon {
          width: 15px;
          height: 15px;
        }
      }
      svg path {
        stroke: $black;
      }
      @include hover {
        svg path {
          stroke: $white;
        }
      }

      &.active {
        background-color: $black;
        span {
          color: white;
        }
        svg path {
          stroke: white;
        }
      }
    }
  }
  &-left-col {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 40px;
    width: 44.581818181818186%;

    @include maxq(desktop-lg) {
      order: 2;
    }

    @include maxq(desktop) {
      grid-column-gap: 25px;
      order: 2;
      width: 100%;
    }
    @include maxq(tablet) {
      width: fit-content;
      grid-column-gap: 8px;
      order: initial;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
  }
  &-left-box {
    cursor: pointer;
    &-icon {
      height: fit-content;
    }
    svg {
      @include easeInOut(350ms, all);
      height: 52px;
      width: auto;
    }

    span {
      margin-left: 16px;
      font-size: 24px;
      line-height: 28px;
    }

    @include hover {
      svg {
        background-color: #fcf192;
      }
    }

    @include maxq(desktop-lg) {
      span {
        font-size: 16px;
        line-height: 18px;
      }

      svg {
        height: 38px;
      }
    }

    @include maxq(desktop) {
      span {
        font-size: 14px;
        line-height: 16px;
      }

      svg {
        height: 38px;
      }
    }

    @include maxq(tablet) {
      span {
        display: none;
      }
    }
  }

  &-mid-col {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    .button--8 {
      background-color: $black;
      padding-right: 34px;
      padding-left: 34px;
      margin-top: 16px;
      span {
        color: $white;
      }
    }

    h4 {
      @include weight(400);
      margin-bottom: 8px;
      font-size: 12px;
      line-height: 14px;
    }

    h3 {
      font-size: 32px;
      line-height: 28px;
      @include weight(300);
    }

    svg {
      height: 19px;
      margin-top: 2px;
      margin-left: 5px;
    }
  }

  &-bottom {
    padding-top: 32px;
    margin-top: 24px;
    border-top: $brightGray 1px solid;

    h3 {
      font-size: 32px;
      line-height: 38px;
      margin-right: 5px;
      @include weight(400);
    }
    svg {
      height: 25px;
      width: auto;
    }
    .button--8 {
      background-color: $black;
      margin-left: 24px;
      padding-right: 34px;
      padding-left: 34px;
      span {
        color: white;
      }
    }

    h4 {
      @include weight(400);
      margin-bottom: 8px;
      font-size: 12px;
      line-height: 14px;
      opacity: 0.6;
    }

    @include maxq(mobile) {
      position: relative;
      padding-bottom: 63px;
      padding-top: 16px;
      margin-top: 15px;

      &-price {
        padding-left: 25px;
        h3 {
          font-size: 26px;
          line-height: 26px;
        }
      }
      .dir-col {
        order: 2;
        background-color: $disabled;
        padding: 10px;
        h3 {
          font-size: 20px;
          line-height: 22px;
        }
      }
      svg {
        height: 15px;
      }

      .button--8 {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        margin-left: 0;
        margin-right: 0px;
        height: 48px;
      }
    }

    @include maxq(mobile-sm) {
      &-price {
        padding-left: 0px;
        flex-grow: 1;

        & > div {
          width: 102%;
        }

        h3 {
          font-size: 20px;
          line-height: 20px;
        }
      }

      .dir-col {
        h3 {
          font-size: 16px;
          line-height: 18px;
        }
      }
    }
  }
}

.sidebar--contact-request {
  display: flex;
  align-items: flex-end;

  .button {
    margin-bottom: 22px;
    background-color: $black;

    .icon--loading {
      svg path:nth-child(2) {
        fill: white;
        opacity: 1;
      }
    }

    &__content {
      color: white;
    }

    @include hover {
      background-color: white;
      border: 1px solid #e1e1e1;
      .button__content {
        color: $black;
      }

      .icon--loading {
        svg path:nth-child(2) {
          fill: initial;
          opacity: initial;
        }
      }
    }
  }

  .book-call-title {
    width: 100%;
    @include weight(300);
    font-size: 40px;
    line-height: 49px;
    text-transform: unset;
    font-feature-settings: unset;
  }

  @include maxq(mobile) {
    .base-modal__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
  }
}

.sidebar__title {
  h2 {
    @include uppercase;

    font-size: 38px;
    @include maxq(desktop-2k) {
      font-size: 14px;
      line-height: 17px;
    }
  }
}
</style>
