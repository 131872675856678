<script setup lang="ts">
/**
 * @author Irakli Kandelaki
 */

/** IMPORT HOOKS */
import { computed, ref } from "vue";

/** IMPORT STORE */
import { useBuildingStore } from "@/store/building";
import { useMainStore } from "@/store/main";

/** IMPORT COMPOSABLES */
import { humanReadableNum } from "@/composables/utils/template";
// import { sendEvent } from "@/composables/utils/sendEvent";

/** IMPORT COMPONENTS */
import NumberSVG from "../SVG/NumberSVG.vue";
import CalculatorIcon from "@/components/regularComponents/UIComponents/SVG/Calculator.vue";
import Button from "@/components/regularComponents/UIComponents/Button.vue";
import BaseModal from "@/components/regularComponents/UIComponents/Modals/BaseModal.vue";
import BookingForm from "@/components/regularComponents/FormComponents/BookingForm.vue";
import PaymentCalculator from "@/components/regularComponents/UIComponents/Project/PaymentCalculator/PaymentCalculator.vue";
import DollarSign from "../SVG/DollarSign.vue";
import EuroSign from "../SVG/EuroSign.vue";
import LariSign from "../SVG/LariSign.vue";

/** RETRIEVE STORE */
const buildingStore = useBuildingStore();
const mainStore = useMainStore();

const route = useRoute();

/** REACTIVE DATA */
const showContactRequestModal = ref(false);
const isPaymentCalculatorOpen = ref(false);

const isDesktop = computed(() => mainStore.windowWidth > mainStore.breakpoints.tablet);

const getInstalmentValue = computed(() => {
  if (!buildingStore?.selectedFlat?.price) return;
  const res = (buildingStore?.selectedFlat?.price / 100) * 30;

  return humanReadableNum(buildingStore.getPrice(res, true));
});

const openModal = () => {
  showContactRequestModal.value = true;

  // if (buildingStore.selectedProject?.slug == "montemar") {
  //   sendEvent("Montemar - Request form open");
  // }

  // if (buildingStore.selectedProject?.slug == "portline") {
  //   sendEvent("Portline - Request form open");
  // }

  // if (buildingStore.selectedProject?.slug == "bulvar-point") {
  //   sendEvent("Boulevard - Request form open");
  // }

  // if (buildingStore.selectedProject?.slug == "midtown") {
  //   sendEvent("Midtown - Request form open");
  // }
};
</script>

<template>
  <div class="flat__right-col d-flex dir-col al-center j-btw bg-white">
    <div v-if="mainStore.windowWidth >= 984" class="flat__right-col-title">
      <h1 class="uppercase">
        {{ buildingStore?.selectedFlat?.flatTypeTitle }}
      </h1>
      <div class="flat__right-col-underline"></div>
    </div>

    <div class="flat__right-col-ap-num d-flex dir-col al-center">
      <div class="d-flex">
        <NumberSVG />
        <h3>{{ buildingStore?.selectedFlat?.flat_number }}</h3>
      </div>
      <span class="flat__right-col-subtitle uppercase">{{ $t("apartment") }}</span>
      <div class="flat__right-col-underline"></div>
    </div>

    <div class="flat__right-col-area d-flex dir-col al-center">
      <div class="pos-rel d-flex al-center">
        <h3>{{ buildingStore?.selectedFlat?.area_m2 }}</h3>
        <span>{{ $t("m") }}</span
        ><sup>2</sup>
      </div>
      <span class="flat__right-col-subtitle uppercase">{{ $t("area") }}</span>
      <div class="flat__right-col-underline"></div>
    </div>

    <div class="flat__right-col-floor-num d-flex dir-col al-center">
      <h3>{{ buildingStore?.selectedFlat?.floor_number }}</h3>

      <span class="flat__right-col-subtitle uppercase">{{ $t("floor") }}</span>
      <div class="flat__right-col-underline"></div>
    </div>

    <div
      class="flat__right-col-price d-flex dir-col al-center"
      v-if="mainStore.windowWidth >= 984"
    >
      <span class="flat__right-col-subtitle uppercase">{{ $t("price") }}</span>
      <div class="d-flex">
        <h3>
          {{
            !buildingStore?.selectedFlat?.hide_price?.hide_price
              ? buildingStore?.getPrice(
                  buildingStore?.selectedFlat?.offer_price ||
                    buildingStore?.selectedFlat?.price,
                  true
                )
              : $t("hidden")
          }}
        </h3>
        <template v-if="!buildingStore?.selectedFlat?.hide_price?.hide_price">
          <DollarSign v-if="buildingStore.currency === 'usd'" />
          <EuroSign v-else-if="buildingStore.currency === 'eur'" />
          <LariSign v-else />
        </template>
      </div>

      <div class="flat__right-col-underline"></div>
    </div>

    <div
      class="flat__right-col-instalment d-flex dir-col al-center"
      v-if="isDesktop && !buildingStore?.selectedFlat?.hide_price?.hide_price"
    >
      <span class="flat__right-col-subtitle uppercase">{{ $t("instalment from") }}</span>
      <div class="d-flex">
        <h3>{{ getInstalmentValue }}</h3>
        <DollarSign v-if="buildingStore.currency === 'usd'" />
        <EuroSign v-else-if="buildingStore.currency === 'eur'" />
        <LariSign v-else />
      </div>
      <div class="d-flex pointer" @click="isPaymentCalculatorOpen = true">
        <CalculatorIcon />
        <span class="l-12 uppercase">{{ $t("payment calculator") }}</span>
      </div>
      <div class="flat__right-col-underline"></div>
    </div>

    <Button
      v-if="isDesktop"
      btn-text="send request"
      :btn-type="8"
      :uppercase="true"
      :full="true"
      @click="openModal"
    />
  </div>

  <!-- MODALS -->
  <Teleport to="body">
    <Transition name="fade">
      <BaseModal
        title="SEND REQUEST"
        :expandToFullScreen="true"
        :hideBackDrop="false"
        class="sidebar--contact-request booking-modal"
        v-if="showContactRequestModal"
        @close="showContactRequestModal = false"
      >
        <BookingForm
          emailRequired
          title="Contact Request"
          formType="flatBooking"
          noteText="We will definitely contact you within the next few days or hours and provide you with detailed information about the apartment."
        /> </BaseModal
    ></Transition>

    <Transition name="fade">
      <BaseModal
        title="PAYMENT CALCULATOR"
        :expandToFullScreen="true"
        :hideBackDrop="false"
        class="sidebar--contact-request"
        v-if="isPaymentCalculatorOpen"
        @close="isPaymentCalculatorOpen = false"
      >
        <PaymentCalculator />
      </BaseModal>
    </Transition>
  </Teleport>
</template>

<style lang="scss">
.flat__right-col {
  padding-top: 70px;
  padding-bottom: 70px;
  padding-left: 83px;
  padding-right: 83px;
  min-width: 476px;
  border-left: $brightGray 1px solid;

  @include maxq(desktop-lg) {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 53px;
    padding-right: 53px;
    min-width: 376px;
  }

  @include maxq(desktop) {
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 33px;
    padding-right: 33px;
    min-width: 304px;
  }

  @include maxq(tablet) {
    min-width: initial;
    width: 100%;
    flex-direction: row;
    border-left: none;
    padding: 0;
  }

  &-underline {
    display: block;
    height: 1px;
    background-color: $brightGray;
    // background-color: red;
    width: 100%;
    margin-top: 16px;
    @include maxq(tablet) {
      position: absolute;
      top: -5px;
      right: 0;
      width: 1px;
      height: 44px;
    }
    @include maxq(mobile) {
      top: -15px;
    }
  }

  &-subtitle {
    margin-top: 8px;
    font-size: 34px;
    line-height: 36px;
    @include weight(400);

    @include maxq(desktop-lg) {
      font-size: 12px;
      line-height: 18px;
    }

    @include maxq(tablet) {
      margin-top: 0;
    }
  }
  &-title {
    margin-bottom: 32px;
    h1 {
      @include weight(500);
      color: $black;
      font-size: 58px;
      line-height: 66px;
      @include maxq(desktop-lg) {
        font-size: 28px;
        line-height: 36px;
      }
    }
    @include maxq(desktop) {
      margin-bottom: 20px;
    }
    @include maxq(desktop-sm) {
      margin-bottom: 15px;
      h1 {
        font-size: 21px;
        line-height: 28px;
      }
    }

    @include maxq(tablet) {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      margin-bottom: 0;
      height: 100%;
      h1 {
        text-align: center;
      }
    }
  }
  &-ap-num,
  &-floor-num {
    h3 {
      font-size: 68px;
      line-height: 68px;
      @include weight(300);
    }
    svg {
      margin-top: 18px;
      margin-right: 2px;
      width: auto;
      height: 40px;
    }

    @include maxq(desktop-lg) {
      h3 {
        font-size: 48px;
        line-height: 48px;
      }

      svg {
        height: 22px;
      }
    }

    @include maxq(desktop) {
      h3 {
        font-size: 32px;
        line-height: 32px;
      }

      svg {
        height: 18px;
        margin-top: 10px;
      }
    }

    @include maxq(desktop-sm) {
      h3 {
        font-size: 24px;
        line-height: 24px;
      }
    }
    @include maxq(tablet) {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      height: 100%;
      svg {
        height: 12px;
      }
    }

    @include maxq(mobile) {
      h3 {
        font-size: 18px;
        line-height: 21px;
      }

      svg {
        margin-top: 7px;
        height: 9px;
      }
    }
  }

  &-ap-num {
    margin-bottom: 32px;
    @include maxq(desktop) {
      margin-bottom: 20px;
    }
    @include maxq(desktop-sm) {
      margin-bottom: 15px;
    }
    @include maxq(tablet) {
      order: 2;
      margin-bottom: 0px;
    }
    @include maxq(mobile) {
    }
  }
  &-floor-num {
    margin-bottom: 48px;
    @include maxq(desktop) {
      margin-bottom: 24px;
    }
    @include maxq(desktop-sm) {
      margin-bottom: 15px;
    }
    @include maxq(tablet) {
      margin-bottom: 0px;
      order: 3;
    }
  }

  &-area {
    margin-bottom: 32px;

    h3 {
      font-size: 94px;
      line-height: 94px;
      margin-right: 16px;
      @include weight(300);
    }
    .d-flex {
      span {
        font-size: 52px;
        line-height: 52px;
      }
      sup {
        position: absolute;
        top: 15px;
        right: -20px;
        font-size: 36px;
        line-height: 36px;
      }
      * {
        @include weight(300);
      }
    }

    @include maxq(desktop-lg) {
      h3 {
        font-size: 64px;
        line-height: 64px;
      }
      .d-flex {
        span {
          font-size: 32px;
          line-height: 32px;
        }
        sup {
          top: 15px;
          right: -9px;
          font-size: 16px;
          line-height: 16px;
        }
      }
    }

    @include maxq(desktop) {
      margin-bottom: 20px;
      h3 {
        font-size: 44px;
        line-height: 44px;
        margin-right: 10px;
      }
      .d-flex {
        span {
          font-size: 22px;
          line-height: 22px;
        }
        sup {
          top: 5px;
          right: -9px;
          font-size: 16px;
          line-height: 16px;
        }
      }
    }

    @include maxq(desktop-sm) {
      margin-bottom: 15px;
      h3 {
        font-size: 32px;
        line-height: 32px;
        margin-right: 10px;
      }
      .d-flex {
        span {
          font-size: 22px;
          line-height: 22px;
        }
        sup {
          top: 5px;
          right: -9px;
          font-size: 16px;
          line-height: 16px;
        }
      }
    }

    @include maxq(tablet) {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      height: 100%;
      order: 4;
      margin-bottom: 0;
      .flat__right-col-subtitle {
        display: none;
      }
    }

    @include maxq(mobile) {
      h3 {
        font-size: 24px;
        line-height: 26px;
      }
      .d-flex {
        sup {
          top: 0px;
          font-size: 12px;
          line-height: 12px;
        }
      }

      .flat__right-col-underline {
        display: none;
      }
    }
  }

  &-price {
    margin-bottom: 24px;

    .flat__right-col-subtitle {
      margin-top: 0;
      margin-bottom: 0;
    }
    h3 {
      width: 100%;
      font-size: 76px;
      line-height: 88px;
      @include weight(400);
      margin-right: 10px;
    }

    svg {
      height: 35px;
      width: auto;
    }

    @include maxq(desktop-lg) {
      h3 {
        font-size: 56px;
        line-height: 68px;
        @include weight(400);
      }
      svg {
        height: 25px;
        width: auto;
      }
    }

    @include maxq(desktop) {
      h3 {
        font-size: 46px;
        line-height: 52px;
        @include weight(400);
      }
      svg {
        height: 25px;
        width: auto;
      }
    }

    @include maxq(desktop-sm) {
      margin-bottom: 15px;

      h3 {
        font-size: 32px;
        line-height: 38px;
        @include weight(400);
      }
      svg {
        height: 25px;
        width: auto;
      }
    }

    @include maxq(tablet) {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      height: 100%;
      order: 5;
      margin-bottom: 0;
      .flat__right-col-subtitle,
      .flat__right-col-underline {
        display: none;
      }
    }
  }

  &-instalment {
    margin-bottom: 48px;
    h3 {
      width: 100%;
      @include weight(400);
      font-size: 60px;
      line-height: 88px;
      margin-bottom: 8px;
      margin-right: 10px;
    }
    .d-flex:nth-child(2) {
      margin-top: 20px;
      svg {
        width: auto;
        height: 32px;
        @include maxq(desktop) {
          height: 22px;
        }
      }
      @include maxq(desktop-sm) {
        margin-top: 15px;
      }
    }
    .d-flex:nth-child(3) {
      align-items: center;
      padding-bottom: 4px;
      padding-left: 4px;
      padding-right: 4px;
      @include easeInOut(250ms, all);

      @include hover {
        background-color: $black;
        span {
          color: white;
        }
        svg {
          path {
            stroke: $white;
          }
        }
      }

      svg {
        width: auto;
        height: 32px;
        margin-right: 10px;
      }
    }

    .flat__right-col-underline {
      margin-top: 0px;
      background-color: $black;
    }

    @include maxq(desktop-lg) {
      h3 {
        font-size: 40px;
        line-height: 48px;
      }
    }
    @include maxq(desktop) {
      margin-bottom: 36px;

      h3 {
        font-size: 30px;
        line-height: 38px;
      }
    }

    @include maxq(desktop-sm) {
      margin-bottom: 20px;

      h3 {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
}
</style>
