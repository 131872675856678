<template>
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 4.5H5.5V7.5H11.5V4.5Z"
      stroke="#231F20"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.5 14V3C13.5 2.72386 13.2761 2.5 13 2.5L4 2.5C3.72386 2.5 3.5 2.72386 3.5 3V14C3.5 14.2761 3.72386 14.5 4 14.5H13C13.2761 14.5 13.5 14.2761 13.5 14Z"
      stroke="#231F20"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6 10.25C6.27614 10.25 6.5 10.0261 6.5 9.75C6.5 9.47386 6.27614 9.25 6 9.25C5.72386 9.25 5.5 9.47386 5.5 9.75C5.5 10.0261 5.72386 10.25 6 10.25Z"
      fill="#231F20"
    />
    <path
      d="M8.5 10.25C8.77614 10.25 9 10.0261 9 9.75C9 9.47386 8.77614 9.25 8.5 9.25C8.22386 9.25 8 9.47386 8 9.75C8 10.0261 8.22386 10.25 8.5 10.25Z"
      fill="#231F20"
    />
    <path
      d="M11 10.25C11.2761 10.25 11.5 10.0261 11.5 9.75C11.5 9.47386 11.2761 9.25 11 9.25C10.7239 9.25 10.5 9.47386 10.5 9.75C10.5 10.0261 10.7239 10.25 11 10.25Z"
      fill="#231F20"
    />
    <path
      d="M6 12.75C6.27614 12.75 6.5 12.5261 6.5 12.25C6.5 11.9739 6.27614 11.75 6 11.75C5.72386 11.75 5.5 11.9739 5.5 12.25C5.5 12.5261 5.72386 12.75 6 12.75Z"
      fill="#231F20"
    />
    <path
      d="M8.5 12.75C8.77614 12.75 9 12.5261 9 12.25C9 11.9739 8.77614 11.75 8.5 11.75C8.22386 11.75 8 11.9739 8 12.25C8 12.5261 8.22386 12.75 8.5 12.75Z"
      fill="#231F20"
    />
    <path
      d="M11 12.75C11.2761 12.75 11.5 12.5261 11.5 12.25C11.5 11.9739 11.2761 11.75 11 11.75C10.7239 11.75 10.5 11.9739 10.5 12.25C10.5 12.5261 10.7239 12.75 11 12.75Z"
      fill="#231F20"
    />
  </svg>
</template>
