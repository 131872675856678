<script setup lang="ts">
	/**
	 * @author Beka Chkhaidze, Irakli Kandelaki
	 */

	/** IMPORT HOOKS */
	import { computed, onBeforeMount, ref } from "vue";
	import { useBuildingStore } from "@/store/building";
	import { formatNumber, selectFormatter } from "@/composables/utils/template";
	

	/** IMPORT TYPES */
	import type { SelectInterface } from "@/types/FormInterface";

	/** IMPORT COMPONENTS */
	import slider from "vue3-slider";
	import SelectInput from "@/components/regularComponents/FormComponents/SelectInput.vue";
	import Price from "./parts/Price.vue";
	import Input from "@/components/regularComponents/FormComponents/Input.vue";

	/** STORE */
	const buildingStore = useBuildingStore();

	/** REACTIVE DATA */
	const durationValue = ref<SelectInterface>();
	const currency = ref<SelectInterface>();
	const range = ref<number>();

	const priceValue = computed({
		get() {
			const price = buildingStore?.selectedFlat?.price?.toString();
			const converted = buildingStore.getPrice(price, true);
			return converted.toString();
		},
		set() {},
	});

	const investmentDurationList = computed(() => {
		const res = [];
		const max = Number(buildingStore?.selectedProject?.maxMonth);

		for (let i = 1; i <= max; i++) {
			res.push({
				title: `${i} month`,
				value: `${i}`,
			});
		}

		return res;
	});

	const getInstalmentValue = computed(() => {
		if (!buildingStore?.selectedFlat?.price) return 0;
		if (!range.value) return;

		const res = (buildingStore?.selectedFlat?.price / 100) * range.value;

		return res;
	});

	const minPercent = computed(() => {
		const percent = Number(buildingStore.selectedProject?.minPercent) || 0;
		range.value = percent;
		return percent;
	});

	const filteredCurrencies = computed(() =>
		buildingStore.currencies.filter((el) => el.value != currency.value?.value)
	);
	const getMonthlyPayment = computed(() => {
		if (!buildingStore?.selectedFlat?.price) return 0;

		if (!Number(durationValue.value?.value)) {
			console.warn("Can't find durationValue");
			return;
		}

		if (!getInstalmentValue.value) return;

		const price = buildingStore.selectedFlat?.offer_price
			? Number(buildingStore.selectedFlat.offer_price)
			: buildingStore.selectedFlat.price;

		const res: number =
			(price - getInstalmentValue.value) / Number(durationValue.value?.value);

		return res;
	});

	/** METHODS */
	const onDurationSelect = (param: SelectInterface | undefined) => {
		durationValue.value = selectFormatter(param?.value!);
	};

	const changeCurrency = (key: any) => {
		const currencyVal = key.value as "gel" | "eur" | "usd";
		buildingStore.currency = currencyVal;
	};

	/** LIFECYCLE */
	onBeforeMount(() => {
		const initialValue = selectFormatter(
			buildingStore.selectedProject?.maxMonth!
		);
		durationValue.value = initialValue;

		currency.value = selectFormatter("usd", "usd");
		buildingStore.currency = currency.value.value as "gel" | "eur" | "usd";
	});
</script>

<template>
	<section class="payment-calculator">
		<div>
			<h3 class="payment-calculator__title hl-1">
				{{ $t("a reliable investment") }}
			</h3>
			<Input
				v-model="priceValue"
				class="mb-16 pos-rel z-2"
				inputDisabled
				label="Apartment price (without fit out)"
			>
				<template #right>
					<div class="pointer select">
						<SelectInput
							@select="changeCurrency"
							v-model="currency"
							class="w-full"
							label="Currency"
							:items="filteredCurrencies"
						/>
					</div>
				</template>
			</Input>

			<SelectInput
				:items="investmentDurationList"
				v-model="durationValue"
				label="Instalment duration"
				class="mb-8"
				@select="onDurationSelect"
			/>

			<div class="payment-calculator__slider">
				<div class="label d-flex">
					<p>{{ $t("inner instalment") }}</p>
					<span v-if="getInstalmentValue"> ({{ range }}%)</span>
				</div>

				<div class="slider">
					<ClientOnly>
						<slider
							v-model="range"
							color="#FECB29"
							track-color="#EBEBE7"
							alwaysShowHandle
							:handleScale="3"
							:min="minPercent"
							:max="100"
						/>
					</ClientOnly>
				</div>
			</div>

			<ul class="payment-calculator__payments">
				<Price
					class="item"
					v-if="getMonthlyPayment != undefined"
					label="Monthly payment"
					:price="String(buildingStore.getPrice(getInstalmentValue, true))"
					:currentCurrency="currency?.value!"
				/>

				<Price
					class="item"
					v-if="getMonthlyPayment != undefined"
					label="Quarterly payment"
					:price="String(buildingStore.getPrice(getMonthlyPayment, true))"
					:currentCurrency="currency?.value!"
				/>
			</ul>
		</div>

		<div>
			<div class="payment-calculator__bank-instalment">
				<div class="title p-14 uppercase">{{ $t("bank instalment") }}</div>
				<p class="text op-6">
					{{
						$t(`all apartment owners will be involved in an electronic management system, which
        allows the owner to receive detailed`)
					}}
				</p>
			</div>
		</div>
	</section>
</template>

<style lang="scss" src="./style/paymentCalculator.scss" />
