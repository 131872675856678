<script setup lang="ts">
/**
 * @author Irakli Kandelaki
 */

import { computed, ref, watch } from "vue";
import { useBuildingStore } from "@/store/building";
import { useMainStore } from "@/store/main";

import { Swiper, SwiperSlide } from "swiper/vue";
import { FreeMode } from "swiper";

import SunlightSlide from "./SVG/SunlightSlide.vue";
import SunlightCorner from "./SVG/SunlightCorner.vue";

import type { Swiper as SwiperInterface } from "swiper/types";

interface Hour {
  title: string;
  value: number;
}

const props = defineProps<{
  activeSeason: number;
}>();

const buildingStore = useBuildingStore();
const mainStore = useMainStore();

const isMobile = computed(() => mainStore.windowWidth < mainStore.breakpoints.mobile);

const rotateValue = ref(0);
const swiper = ref<SwiperInterface>();

const sliderSummerData = computed(() => {
  const resArray: Hour[] = [];
  if (props.activeSeason === 1) {
    for (let i = 4; i < 22; i++) {
      resArray.push({
        title: i >= 10 ? `${i}:00` : `0${i}:00`,
        value: i,
      });
    }
  } else {
    for (let i = 9; i < 19; i++) {
      resArray.push({
        title: i >= 10 ? `${i}:00` : `0${i}:00`,
        value: i,
      });
    }
  }
  return resArray;
});

const handleSlideChange = (sw: SwiperInterface) => {
  const activeIndex = sw.activeIndex;
  if (props.activeSeason === 1) {
    if (activeIndex === 0) rotateValue.value = 0;
    else rotateValue.value = activeIndex * 10.5882352941;
  } else {
    if (activeIndex === 0) rotateValue.value = 0;
    else rotateValue.value = activeIndex * 20;
  }
};

const onSwiper = (e: SwiperInterface) => {
  swiper.value = e;
};

watch(
  () => props.activeSeason,
  () => {
    swiper.value?.slideTo(0);
  }
);
</script>

<template>
  <section class="sunlight-map pos-rel">
    <div class="pos-rel sunlight-map__img-wrapper">
      <img
        :src="
          buildingStore?.selectedFlat?.flat_type?.image_sunlight?.[0]?.devices?.desktop
        "
        alt="image"
        loading="lazy"
      />
      <div
        class="sunlight-corner-overlay pos-abs"
        :style="{ transform: `rotate(${rotateValue}deg)` }"
      >
        <SunlightCorner class="pos-abs sunlight-corner" />
      </div>
    </div>

    <Swiper
      slidesPerView="auto"
      :speed="400"
      @swiper="onSwiper"
      :grabCursor="true"
      :updateOnWindowResize="true"
      :slidesOffsetBefore="mainStore.windowWidth / (isMobile ? 4 : 2)"
      :slidesOffsetAfter="mainStore.windowWidth / (isMobile ? 4 : 2)"
      :freeMode="{
        enabled: true,
        sticky: true,
        minimumVelocity: 2.02,
      }"
      :modules="[FreeMode]"
      @slideChange="handleSlideChange"
    >
      <SwiperSlide v-for="(item, index) in sliderSummerData" :key="index">
        <p class="text-center mb-24 time">{{ item?.title }}</p>
        <SunlightSlide />
      </SwiperSlide>
    </Swiper>
  </section>
</template>

<style lang="scss">
.sunlight-map {
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 110px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .swiper {
    margin-left: unset;
    margin-right: unset;
  }

  .time {
    font-family: "Montserrat";

    font-size: 40px;

    @include maxq(desktop-2k) {
      font-size: 32px;
    }

    @include maxq(desktop-lg) {
      font-size: 24px;
      line-height: 26px;
    }
  }

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  &__img-wrapper {
    width: fit-content;
    margin: 0 auto;

    // width: 1500px;
    // height: 1500px;

    // @include maxq(desktop-2k) {
    //   width: 970px;
    //   height: 970px;
    // }

    @include maxq(desktop-lg) {
      width: 500px;
      height: 500px;
      margin-top: 150px;
    }

    @include maxq(desktop) {
      margin-top: 80px;
      width: 682px;
      height: 422px;
    }

    @include maxq(tablet) {
      width: 506px;
      height: 314px;
      margin-top: 135px;
    }

    @include maxq(mobile) {
      width: 240px;
      height: 148px;
    }

    @include maxq(mobile-sm) {
      margin-top: 50%;
      width: 200px;
    }
  }

  &__buttons {
    display: flex;
    padding: 8px;
    border: 1px solid $brightGray;
    width: max-content;

    .button {
      height: unset;
      padding: 40px 48px;

      @include maxq(desktop-2k) {
        padding: 24px;
      }

      @include maxq(desktop-lg) {
        padding: 12px 16px;
      }

      &__content {
        font-size: 40px;
        line-height: 1.3;

        @include maxq(desktop-2k) {
          font-size: 28px;
        }

        @include maxq(desktop-lg) {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
  &-modal {
    .base-modal {
      &--wrapper {
        padding: 48px;
        padding-bottom: 24px;

        @include maxq(mobile) {
          padding: 24px;
        }
      }
      &__content {
        height: 100%;
        padding: 0;
        display: flex;
        flex-direction: column;

        &-head {
          margin-bottom: 0;
          .head-in {
            z-index: 2;
            width: 50%;
            display: flex;
            margin-left: auto;
            justify-content: space-between;

            @include maxq(mobile) {
              width: 100%;
              flex-direction: column-reverse;
              gap: 24px;
            }
          }
        }
      }
    }
  }

  .swiper {
    &-slide {
      width: 151px;
      height: auto;
      &-active {
        rect:first-child {
          fill: $black;
        }
      }
    }
  }

  .sunlight-corner {
    top: -10%;
    right: -35%;

    @include maxq(desktop) {
      top: -46%;
      right: -31%;
    }
    &-overlay {
      // background: rgba(255, 0, 0, 0.12);
      top: 10%;
      left: 10%;
      width: 80%;
      height: 80%;
      transform: rotate(0deg);
      transition: transform 0.6s cubic-bezier(0.17, 0.67, 0.77, 0.91);

      @include maxq(desktop) {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      svg {
        width: 700px;
        height: 700px;
        top: -11%;
        right: -43%;

        @include maxq(desktop-2k) {
          width: 500px;
          height: 500px;
        }

        @include maxq(desktop-lg) {
          top: -81%;
          right: -69%;
        }

        @include maxq(desktop) {
          width: unset;
          height: unset;
          top: -33%;
          right: -23%;
        }

        @include maxq(tablet) {
          top: -52%;
          right: -38%;
        }

        @include maxq(mobile) {
          top: -109%;
          right: -76%;
        }

        @include maxq(mobile-sm) {
          top: -96%;
          right: -86%;
        }
      }
    }
  }
}
</style>
