<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30 16H18V22H30V16Z"
      stroke="#222222"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M34 35V13C34 12.4477 33.5523 12 33 12L15 12C14.4477 12 14 12.4477 14 13V35C14 35.5523 14.4477 36 15 36H33C33.5523 36 34 35.5523 34 35Z"
      stroke="#222222"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19 27.5C19.5523 27.5 20 27.0523 20 26.5C20 25.9477 19.5523 25.5 19 25.5C18.4477 25.5 18 25.9477 18 26.5C18 27.0523 18.4477 27.5 19 27.5Z"
      fill="#222222"
    />
    <path
      d="M24 27.5C24.5523 27.5 25 27.0523 25 26.5C25 25.9477 24.5523 25.5 24 25.5C23.4477 25.5 23 25.9477 23 26.5C23 27.0523 23.4477 27.5 24 27.5Z"
      fill="#222222"
    />
    <path
      d="M29 27.5C29.5523 27.5 30 27.0523 30 26.5C30 25.9477 29.5523 25.5 29 25.5C28.4477 25.5 28 25.9477 28 26.5C28 27.0523 28.4477 27.5 29 27.5Z"
      fill="#222222"
    />
    <path
      d="M19 32.5C19.5523 32.5 20 32.0523 20 31.5C20 30.9477 19.5523 30.5 19 30.5C18.4477 30.5 18 30.9477 18 31.5C18 32.0523 18.4477 32.5 19 32.5Z"
      fill="#222222"
    />
    <path
      d="M24 32.5C24.5523 32.5 25 32.0523 25 31.5C25 30.9477 24.5523 30.5 24 30.5C23.4477 30.5 23 30.9477 23 31.5C23 32.0523 23.4477 32.5 24 32.5Z"
      fill="#222222"
    />
    <path
      d="M29 32.5C29.5523 32.5 30 32.0523 30 31.5C30 30.9477 29.5523 30.5 29 30.5C28.4477 30.5 28 30.9477 28 31.5C28 32.0523 28.4477 32.5 29 32.5Z"
      fill="#222222"
    />
    <rect x="0.5" y="0.5" width="47" height="47" stroke="#E1E1E1" />
  </svg>
</template>
