<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_495_8571)">
      <path
        d="M32.1818 15H15.8182C15.3663 15 15 15.3663 15 15.8182V32.1818C15 32.6337 15.3663 33 15.8182 33H32.1818C32.6337 33 33 32.6337 33 32.1818V15.8182C33 15.3663 32.6337 15 32.1818 15Z"
        stroke="#231F20"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M48.4545 18H37.5455C37.2442 18 37 18.2442 37 18.5455V29.4545C37 29.7558 37.2442 30 37.5455 30H48.4545C48.7558 30 49 29.7558 49 29.4545V18.5455C49 18.2442 48.7558 18 48.4545 18Z"
        stroke="#231F20"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.4545 18H-0.454545C-0.755792 18 -1 18.2442 -1 18.5455V29.4545C-1 29.7558 -0.755792 30 -0.454545 30H10.4545C10.7558 30 11 29.7558 11 29.4545V18.5455C11 18.2442 10.7558 18 10.4545 18Z"
        stroke="#231F20"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <rect x="0.5" y="0.5" width="47" height="47" stroke="#E1E1E1" />
    <defs>
      <clipPath id="clip0_495_8571">
        <rect width="32" height="32" fill="white" transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>
</template>
