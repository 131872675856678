<script setup lang="ts">
/**
 * @author Alexander Kenchoshvili, Luka Tchigladze
 */

/** IMPORT HOOKS */

import { useRoute } from "vue-router";

/** IMPORT STORE */
import { useMainStore } from "@/store/main/index";
import { useBuildingStore } from "@/store/building/index";

import { getTaxonomySS } from "@/composables/core/func";

/** IMPORT ICONS */

/** IMPORT COMPONENTS */
import FlatsRightCol from "./parts/FlatsRightCol.vue";
import FlatsFooter from "./parts/FlatsFooter.vue";
import BurgerDropdown from "@/components/regularComponents/UIComponents/BurgerDropdown/BurgerDropdown.vue";
import Dropdown from "@/components/regularComponents/UIComponents/Dropdown.vue";
import Button from "@/components/regularComponents/UIComponents/Button.vue";

/** IMPORT TYPES */
import type { SelectInterface } from "@/types/FormInterface";

await getTaxonomySS("project_status");
await getTaxonomySS("type_view");

const route = useRoute();
const router = useRouter();

const mainStore = useMainStore();
const buildingStore = useBuildingStore();

/** REACTIVE DATA */
const isDesktop = computed(() => mainStore.windowWidth > mainStore.breakpoints.tablet);
const routeFullPath = computed(() => route?.fullPath);

const is3dImageActive = ref(true);

const dropActive = ref(false);

const currency = ref("usd");

const titleActive = ref(false);
const projectSlug = ref("");
const projectTitle = ref("");

const projectItems = computed(() => {
  if (projectSlug.value && buildingStore?.getProjectNames?.length) {
    const index = buildingStore.getProjectNames.findIndex((proj) =>
      proj.value.includes(projectSlug.value)
    );
    if (index > -1) {
      titleActive.value = false;
      let items = [...buildingStore?.getProjectNames];
      items[index].isActive = true;
      projectTitle.value = items[index]?.title;

      return [...items.splice(index, 1), ...items];
    }
  }

  return buildingStore.getProjectNames || [];
});

const onEvents = {
  click() {
    dropActive.value = !dropActive.value;
  },
};

const currencyTitle = computed(
  () => buildingStore.currencies.find((curr) => curr.value === currency.value)?.title
);

const selectedFlat = computed(() => buildingStore?.selectedFlat);

const goBack = () => {
  if (buildingStore?.selectedFloor?.route) {
    router.push({ path: buildingStore?.selectedFloor?.route });
  }
};
const onProjectSelect = (project?: SelectInterface) => {
  if (project?.value) router.push(project.value);
};
const changeCurrency = (key: string) => {
  const currencyVal = key as "gel" | "eur" | "usd";
  currency.value = currencyVal;
  buildingStore.currency = currencyVal;
};

const leftView = computed(() => {
  if (!mainStore?.terms?.type_view) return;
  return mainStore?.terms?.type_view.filter(
    (item) =>
      item.side === "left" &&
      buildingStore?.selectedProject?.taxonomy?.type_view?.includes(item?.id)
  );
});
const rightView = computed(() => {
  if (!mainStore?.terms?.type_view) return;
  return mainStore?.terms?.type_view.filter(
    (item) =>
      item.side === "right" &&
      buildingStore?.selectedProject?.taxonomy?.type_view?.includes(item?.id)
  );
});
const topView = computed(() => {
  if (!mainStore?.terms?.type_view) return;
  return mainStore?.terms?.type_view.filter(
    (item) =>
      item.side === "top" &&
      buildingStore?.selectedProject?.taxonomy?.type_view?.includes(item?.id)
  );
});
const bottomView = computed(() => {
  if (!mainStore?.terms?.type_view) return;
  return mainStore?.terms?.type_view.filter(
    (item) =>
      item.side === "bottom" &&
      buildingStore?.selectedProject?.taxonomy?.type_view?.includes(item?.id)
  );
});

/** LIFECYCLE HOOKS */
onBeforeMount(async () => {
  await buildingStore.getProjects({
    forceRefetch: true,
    params: { limit: 1000 },
  });
});

/** WATCHERS */
watch(
  routeFullPath,
  (n) => {
    projectSlug.value = route.params?.slugs?.[1] as string;
  },
  {
    immediate: true,
  }
);
watch(
  () => mainStore.lang,
  async () => {
    await mainStore.setTaxonomy("type_view");
    await buildingStore.getProjects({
      forceRefetch: true,
      params: { limit: 1000 },
    });
  }
);
</script>

<template>
  <div class="flat d-flex pos-rel">
    <div class="flat__left-col pos-rel bg-lotion">
      <div class="flat__left-col-header d-flex pos-abs">
        <div class="flat__left-col-header-inner d-flex" v-if="isDesktop">
          <BurgerDropdown
            :items="projectItems"
            @select="onProjectSelect"
            :titleActive="titleActive"
          />
          <Button @click="goBack" :btn-type="8" icon="arrow" btn-text="Back" />

          <div class="currency" v-if="!selectedFlat?.hide_price?.hide_price">
            <Dropdown
              v-on="onEvents"
              :active="dropActive"
              v-click-outside="() => (dropActive = false)"
            >
              <span class="currency__dropdown--value">{{ currencyTitle }}</span>

              <template #body>
                <div class="currency__dropdown--container d-flex dir-col">
                  <div class="currency__dropdown--items pos-rel">
                    <div
                      v-for="curr in buildingStore.currencies.filter(
                        (c) => c.value != currency
                      )"
                      class="currency__dropdown--item d-flex al-center p-16-regular c-darker-blue br-4"
                      @click="changeCurrency(curr.value)"
                    >
                      {{ curr?.title }}
                    </div>
                  </div>
                </div>
              </template>
            </Dropdown>
          </div>
        </div>

        <div class="flat__left-col-header-inner d-flex w-full" v-else>
          <Button
            @click="goBack"
            :btn-type="8"
            icon="arrow"
            btn-text="back"
            v-if="mainStore.windowWidth >= 984"
          />
          <FlatsRightCol />

          <div
            class="currency"
            v-if="!selectedFlat?.hide_price?.hide_price && mainStore.windowWidth >= 984"
          >
            <Dropdown
              v-on="onEvents"
              :active="dropActive"
              v-click-outside="() => (dropActive = false)"
            >
              <span class="currency__dropdown--value">{{ currencyTitle }}</span>

              <template #body>
                <div class="currency__dropdown--container d-flex dir-col">
                  <div class="currency__dropdown--items pos-rel">
                    <div
                      v-for="curr in buildingStore.currencies.filter(
                        (c) => c.value != currency
                      )"
                      class="currency__dropdown--item d-flex al-center p-16-regular c-darker-blue br-4"
                      @click="changeCurrency(curr.value)"
                    >
                      {{ curr?.title }}
                    </div>
                  </div>
                </div>
              </template>
            </Dropdown>
          </div>
        </div>
      </div>

      <div class="flat__left-col-img-wrapper d-flex j-center al-center">
        <Transition name="fade-out" mode="out-in">
          <img
            v-if="is3dImageActive"
            :src="selectedFlat?.flat_type?.image3d[0]?.devices?.desktop"
            loading="lazy"
          />
          <img
            v-else
            :src="selectedFlat?.flat_type?.image2d[0]?.devices?.desktop"
            alt="image"
            loading="lazy"
          />
        </Transition>
        <template v-for="item in leftView">
          <div class="flat-view--left pos-abs d-flex al-center">
            <div v-if="mainStore.windowWidth >= 984" v-html="item?.icon?.light"></div>
            <span class="l-12">{{ item?.content_title }}</span>
          </div>
        </template>

        <template v-for="item in rightView">
          <div class="flat-view--right pos-abs d-flex al-center">
            <div v-if="mainStore.windowWidth >= 984" v-html="item?.icon?.light"></div>
            <span class="l-12">{{ item?.content_title }}</span>
          </div>
        </template>

        <template v-for="item in topView">
          <div class="flat-view--top pos-abs d-flex j-center al-center">
            <div v-if="mainStore.windowWidth >= 984" v-html="item?.icon?.light"></div>
            <span class="l-12">{{ item?.content_title }}</span>
          </div>
        </template>

        <template v-for="item in bottomView">
          <div class="flat-view--bottom pos-abs d-flex j-center al-center">
            <div v-if="mainStore.windowWidth >= 984" v-html="item?.icon?.light"></div>
            <span class="l-12">{{ item?.content_title }}</span>
          </div>
        </template>
      </div>

      <FlatsFooter
        :is3dImageActive="is3dImageActive"
        @3dImageActive="(e) => (is3dImageActive = e)"
      />
    </div>
    <FlatsRightCol v-if="isDesktop" />
  </div>
</template>

<style lang="scss">
.flat {
  &-view {
    &--left {
      left: 48px;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0.6;
      gap: 10px;

      svg {
        width: 60px;
        height: 60px;

        @include maxq(desktop-2k) {
          width: 44px;
          height: 44px;
        }

        @include maxq(desktop-lg) {
          width: 32px;
          height: 32px;
        }
      }

      @include maxq(desktop-2k) {
        gap: 5px;
      }
      @include maxq(desktop) {
        left: 28px;
      }
      @include maxq(mobile) {
        opacity: 1;
        transform: rotate(-90deg) translateY(-50%);
        left: -15px;

        span {
          @include weight(400);
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
    &--right {
      right: 48px;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0.6;
      gap: 10px;

      svg {
        width: 60px;
        height: 60px;

        @include maxq(desktop-2k) {
          width: 44px;
          height: 44px;
        }

        @include maxq(desktop-lg) {
          width: 32px;
          height: 32px;
        }
      }

      @include maxq(desktop-2k) {
        gap: 5px;
      }
      @include maxq(desktop) {
        right: 28px;
      }
      @include maxq(mobile) {
        opacity: 1;
        transform: rotate(-90deg) translateY(-50%);
        right: -25px;
        span {
          @include weight(400);
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
    &--top {
      top: 164px;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0.6;
      gap: 10px;

      svg {
        width: 60px;
        height: 60px;

        @include maxq(desktop-2k) {
          width: 44px;
          height: 44px;
        }

        @include maxq(desktop-lg) {
          width: 32px;
          height: 32px;
        }
      }

      @include maxq(desktop-2k) {
        top: 172px;
        gap: 5px;
      }
      @include maxq(desktop-lg) {
        top: 156px;
      }
      @include maxq(desktop) {
        top: 130px;
      }
      @include maxq(tablet) {
        top: 99px;
      }
      @include maxq(mobile) {
        top: 74px;
        opacity: 1;
        span {
          @include weight(400);
          font-size: 12px;
          line-height: 16px;
          color: $black;
        }
      }
    }
    &--bottom {
      bottom: 18px;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0.6;
      gap: 10px;

      svg {
        width: 60px;
        height: 60px;

        @include maxq(desktop-2k) {
          width: 44px;
          height: 44px;
        }

        @include maxq(desktop-lg) {
          width: 32px;
          height: 32px;
        }
      }

      @include maxq(desktop-2k) {
        bottom: 36px;
        gap: 5px;
      }
      @include maxq(desktop-lg) {
        bottom: 12px;
      }
      @include maxq(desktop) {
        right: 28px;
      }
      @include maxq(mobile) {
        opacity: 1;
        span {
          @include weight(400);
          font-size: 12px;
          line-height: 16px;
          color: $black;
        }
      }
    }
  }
  &__left-col {
    flex-grow: 1;
    &-img-wrapper {
      position: relative;
      height: calc(100% - 130px);
      width: 100%;
      padding-top: 180px;
      padding-bottom: 35px;
      img {
        position: absolute;

        object-fit: contain;
        height: 70%;
        width: auto;
        max-width: 80%;
      }
      @include maxq(desktop) {
        padding-top: 130px;
        height: calc(100% - 160px);
      }

      @include maxq(tablet) {
        padding-top: 100px;
      }

      @include maxq(mobile) {
        padding-top: 60px;
        padding-bottom: 10px;
        height: calc(100% - 214px);
      }
    }
    &-header {
      padding-left: 48px;
      padding-right: 48px;
      padding-top: 48px;

      @include maxq(desktop) {
        padding-left: 28px;
        padding-right: 28px;
        padding-top: 28px;
      }
      @include maxq(tablet) {
        padding-left: 32px;
        padding-right: 32px;
        padding-top: 24px;
        width: 100%;
      }
      &-inner {
        border: 1px solid #ececec;
        padding: 15px 16px;
        gap: 8px;
        z-index: 6;
        @include maxq(tablet) {
          border: none;
          padding: initial;
        }
      }

      @include maxq(mobile) {
        padding-bottom: 10px;
        padding-top: 10px;
        padding-left: 16px;
        padding-right: 16px;
        border-bottom: 1px solid $brightGray;
      }

      .burger-drop {
        height: 100%;
      }
      .button--8 {
        height: 100%;

        @include maxq(desktop-lg) {
          height: 80px;
        }

        padding: 18px 16px 18px 21px;

        @include hover {
          svg path {
            stroke: $white;
          }
        }

        @include maxq(desktop-lg) {
          height: 60px;
        }
      }
      .currency {
        background-color: $cultured;

        &__dropdown {
          &--value {
            font-size: 26px;

            @include maxq(desktop-2k) {
              font-size: 20px;
            }

            @include maxq(desktop-lg) {
              font-size: 16px;
            }
          }

          &--item {
            font-size: 26px;
            padding: 24px 16px 24px 21px;

            @include maxq(desktop-2k) {
              font-size: 20px;
            }

            @include maxq(desktop-lg) {
              font-size: 16px;
            }
          }
        }
        .custom-dropdown {
          height: 100%;
          padding: 20.5px 16px 20.5px 21px;

          @include easeInOut(350ms, all);
          @include hover {
            background-color: $black;
            .currency__dropdown--value {
              color: $white;
            }

            svg path {
              stroke: $white;
            }
          }

          .drop-icons {
            margin-left: 19px;
          }

          .drop-content {
            right: unset;
            left: 0;
            width: 100%;
            padding: 0;
            .drop-content-in {
              background-color: #f5f5f4;
              min-width: unset;
              padding: 0;
              margin-top: 10px;
              .currency__dropdown--items {
                background-color: $white;
              }
              .currency__dropdown--item {
                @include hover {
                  background-color: $cultured;
                }
              }
            }
          }
        }
      }
    }
    @include maxq(tablet) {
      background-color: white;
    }
  }
}
</style>
