<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 31.5C28.1421 31.5 31.5 28.1421 31.5 24C31.5 19.8579 28.1421 16.5 24 16.5C19.8579 16.5 16.5 19.8579 16.5 24C16.5 28.1421 19.8579 31.5 24 31.5Z"
      stroke="#231F20"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24 12.5V10"
      stroke="#231F20"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.8641 15.8621L14.1016 14.0996"
      stroke="#231F20"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.5 24H10"
      stroke="#231F20"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.8641 32.1377L14.1016 33.9002"
      stroke="#231F20"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24 35.5V38"
      stroke="#231F20"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.1367 32.1377L33.8992 33.9002"
      stroke="#231F20"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M35.5 24H38"
      stroke="#231F20"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.1367 15.8621L33.8992 14.0996"
      stroke="#231F20"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect x="0.5" y="0.5" width="47" height="47" stroke="#E1E1E1" />
  </svg>
</template>
