<template>
  <svg
    width="23"
    height="16"
    viewBox="0 0 23 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 15.488V0.0880003H0.946L11.924 14.102H11.418V0.0880003H12.54V15.488H11.616L0.616 1.474H1.122V15.488H0ZM15.334 9.79V8.976H21.868V9.79H15.334ZM18.612 6.952C17.8933 6.952 17.248 6.80533 16.676 6.512C16.1187 6.204 15.6787 5.79333 15.356 5.28C15.048 4.752 14.894 4.15067 14.894 3.476C14.894 2.78667 15.048 2.18533 15.356 1.672C15.6787 1.144 16.1187 0.733334 16.676 0.440001C17.248 0.146667 17.886 0 18.59 0C19.3087 0 19.9467 0.146667 20.504 0.440001C21.076 0.733334 21.516 1.144 21.824 1.672C22.1467 2.18533 22.308 2.78667 22.308 3.476C22.308 4.15067 22.1467 4.752 21.824 5.28C21.516 5.79333 21.076 6.204 20.504 6.512C19.9467 6.80533 19.316 6.952 18.612 6.952ZM18.59 6.204C19.426 6.204 20.1153 5.95467 20.658 5.456C21.2007 4.94267 21.472 4.28267 21.472 3.476C21.472 2.65467 21.2007 1.99467 20.658 1.496C20.1153 0.982668 19.426 0.726001 18.59 0.726001C17.7687 0.726001 17.0867 0.982668 16.544 1.496C16.0013 1.99467 15.73 2.65467 15.73 3.476C15.73 4.28267 16.0013 4.94267 16.544 5.456C17.0867 5.95467 17.7687 6.204 18.59 6.204Z"
      fill="black"
    />
  </svg>
</template>
