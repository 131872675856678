<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 24V13C14 12.7348 14.1054 12.4804 14.2929 12.2929C14.4804 12.1054 14.7348 12 15 12H27L34 19V24"
      stroke="#231F20"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M27 12V19H34"
      stroke="#231F20"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14 33H16C16.5304 33 17.0391 32.7893 17.4142 32.4142C17.7893 32.0391 18 31.5304 18 31C18 30.4696 17.7893 29.9609 17.4142 29.5858C17.0391 29.2107 16.5304 29 16 29H14V35"
      stroke="#231F20"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M35 29H31.5V35"
      stroke="#231F20"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M34.5 32.5H31.5"
      stroke="#231F20"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24 35C24.7956 35 25.5587 34.6839 26.1213 34.1213C26.6839 33.5587 27 32.7956 27 32C27 31.2044 26.6839 30.4413 26.1213 29.8787C25.5587 29.3161 24.7956 29 24 29H22.25V35H24Z"
      stroke="#231F20"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect x="0.5" y="0.5" width="47" height="47" stroke="#E1E1E1" />
  </svg>
</template>
