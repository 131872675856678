<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 14H34V20"
      stroke="#231F20"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M27 21L34 14"
      stroke="#231F20"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20 34H14V28"
      stroke="#231F20"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21 27L14 34"
      stroke="#231F20"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect x="0.5" y="0.5" width="47" height="47" stroke="#E1E1E1" />
  </svg>
</template>
