<template>
  <svg
    width="341"
    height="325"
    viewBox="0 0 341 325"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M178 156.5C182.142 156.5 185.5 153.142 185.5 149C185.5 144.858 182.142 141.5 178 141.5C173.858 141.5 170.5 144.858 170.5 149C170.5 153.142 173.858 156.5 178 156.5Z"
      stroke="#231F20"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      opacity="0.6"
      d="M178 137.5V135"
      stroke="#231F20"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      opacity="0.6"
      d="M169.863 140.862L168.1 139.1"
      stroke="#231F20"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      opacity="0.6"
      d="M166.5 149H164"
      stroke="#231F20"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      opacity="0.6"
      d="M169.863 157.137L168.1 158.9"
      stroke="#231F20"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      opacity="0.6"
      d="M178 160.5V163"
      stroke="#231F20"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      opacity="0.6"
      d="M186.137 157.137L187.9 158.9"
      stroke="#231F20"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      opacity="0.6"
      d="M189.5 149H192"
      stroke="#231F20"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      opacity="0.6"
      d="M186.138 140.862L187.9 139.1"
      stroke="#231F20"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1 162.5C0.999997 200.403 14.8614 237.115 40.1858 266.281C65.5102 295.448 100.704 315.235 139.678 322.218L167.182 181.968C162.432 181.117 158.142 178.705 155.055 175.15C151.968 171.595 150.279 167.12 150.279 162.5L1 162.5Z"
      stroke="url(#paint0_linear_108_28380)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-dasharray="7 7"
    />
    <defs>
      <linearGradient
        id="paint0_linear_108_28380"
        x1="171"
        y1="162.5"
        x2="44.0364"
        y2="276.932"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.126813" stop-opacity="0" />
        <stop offset="0.22071" stop-opacity="0.32" />
        <stop offset="0.778665" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
</template>
